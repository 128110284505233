
const tenantId = process.env.REACT_APP_AZURE_TENANT_ID;
const clientId = process.env.REACT_APP_AZURE_CLIENT_ID;
const SPA_BASE_URL =
  process.env.REACT_APP_SPA_BASE_URL || 'http://localhost:3000';


if (!tenantId || !clientId) {
  throw new Error("Azure Tenant ID y Client ID deben ser definidas como variables de");
}

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: SPA_BASE_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: ["User.Read"],
  extraQueryParameters: {
    ui_locales: 'es-es',
  }
};

