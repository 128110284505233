// useNotification.ts
import { useDispatch } from 'react-redux';
import { markNotificationAsReadThunk } from '../../store/slices/notificationsSlice';

export const useNotification = () => {
    const dispatch = useDispatch();
  
    const markNotificationAsRead = (notificationId: number) => {
      dispatch(markNotificationAsReadThunk(notificationId) as any);
    };
  
    return { markNotificationAsRead };
  };