// Importaciones necesarias de Redux Toolkit
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define el tipo para el modelo Document
interface Document {
  title: string;
  content: string;
  pdfUrl?: string;
  tituloReferencia?: string;
  textoRefundido?: string;
  descripcion?: string;
  regulation: string; // Asumiendo que es un ID o un modelo simplificado
}

// Define el estado inicial para Document
interface DocumentState {
  documents: Document[];
  loading: boolean;
}

const initialState: DocumentState = {
  documents: [],
  loading: false
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    // Reducer para añadir un nuevo documento
    addDocument(state, action: PayloadAction<Document>) {
      state.documents.push(action.payload);
    },
    // Reducer para actualizar un documento existente
    updateDocument(state, action: PayloadAction<Document>) {
      const index = state.documents.findIndex(doc => doc.title === action.payload.title);
      if (index !== -1) {
        state.documents[index] = action.payload;
      }
    },
    // Reducer para cambiar el estado de loading
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  }
});

export const { addDocument, updateDocument, setLoading } = documentSlice.actions;

export default documentSlice.reducer;
