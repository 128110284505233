import { configureStore } from '@reduxjs/toolkit';
import regulationReducer from './slices/regulationSlice';
import documentReducer from './slices/documentSlice';
import userSlice from './slices/userSlice';
import notificationsSlice from './slices/notificationsSlice';

const store = configureStore({
  reducer: {
    regulation: regulationReducer,
    document: documentReducer,
    user: userSlice,
    notifications: notificationsSlice
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
