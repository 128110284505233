// CalendarContext.tsx
import React, { createContext, useState, ReactNode } from 'react';
import { Regulation } from '../DataTable/dataTypes';

// Asumiendo que tienes la misma definición de interfaz para Event y CalendarContextType
interface Event {
  details: Regulation | null; // Asume que ya tienes una interfaz Regulation definida con todos los campos necesarios
}

export interface CalendarContextType {
  selectedEvent: Event | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<Event | null>>;
}

// Definiendo datos por defecto para el evento
const defaultEvent: Event = {
  details: null,
};

const defaultCalendarContextValue: CalendarContextType = {
  selectedEvent: defaultEvent, // Usando el evento por defecto
  setSelectedEvent: () => {}, // Función vacía, será sobrescrita por el useState en el provider
};

const CalendarContext = createContext<CalendarContextType>(
  defaultCalendarContextValue
);

interface CalendarProviderProps {
  children: ReactNode;
}

export const CalendarProvider: React.FC<CalendarProviderProps> = ({
  children,
}) => {
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(
    defaultEvent
  ); // Inicializando con el evento por defecto

  return (
    <CalendarContext.Provider value={{ selectedEvent, setSelectedEvent }}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarContext;
