import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import './styles.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Regulation } from '../DataTable/dataTypes';
import { useContext } from 'react';
import CalendarContext, {
  CalendarContextType,
} from '../ScheduleDashboard/CalendarContext';

const CalendarComponent = () => {
  const context = useContext(CalendarContext) as CalendarContextType;
  const { setSelectedEvent } = context;

  const regulations = useSelector(
    (state: RootState) => state.regulation.regulations as Regulation[]
  );

  const calendarEvents = regulations.map((regulation) => ({
    title: regulation.code,
    date: regulation.schedule?.deadline?.split('T')[0],
    details: regulation,
  }));

  const handleEventClick = (clickInfo: any) => {
    const regulationDetails = clickInfo.event.extendedProps.details;
    setSelectedEvent({
      details: regulationDetails,
    });
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
      initialView="listWeek"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,listWeek',
      }}
      buttonText={{
        month: 'Mes',
        list: 'Semana',
      }}
      height={'85vh'}
      locale={esLocale}
      events={calendarEvents}
      dayMaxEvents={true}
      eventClick={handleEventClick}
    />
  );
};

export default CalendarComponent;

// Pruebas de Eventos
// [
//   { title: 'UAF_49.1', date: '2024-03-20', allDay: true },
//   { title: 'UAF_53', date: '2024-03-20', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
//   { title: 'UAF_54', date: '2024-03-22', allDay: true },
// ]
