import { useSelector } from 'react-redux';
import DataTable from '../DataTable/DataTable';

function Dashboard() {
  const userName = useSelector((state: any) => state.user?.user?.username);

  return (
    <>
      {/* Verifica si userName existe para mostrar el mensaje de bienvenida */}
      {userName ? (
        <h4 style={{ fontWeight: 'normal' }}>Bienvenido, {userName}</h4>
      ) : (
        <h4>Bienvenido, usuario</h4>
      )}
      <DataTable />
    </>
  );
}

export default Dashboard;
