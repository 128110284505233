import React, { Component, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Importa el ícono de cerrar
import DeleteIcon from '@mui/icons-material/Delete';
import { Regulation, RegulationFormValues } from '../DataTable/dataTypes';
import { UserResponsibilityFields } from './UserResponsibilityFields';
import DocumentFields from './DocumentFields';
import ScheduleFields from './ScheduleFields';
import RegulationFields from './RegulationFields';
import { fetchResponsibilities, fetchUsernames } from '../../api/api';
import { useSubmit } from 'react-router-dom';

interface RegulationFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: Regulation) => void;
  regulation: Regulation | null;
}

const RegulationFormDialog: React.FC<RegulationFormDialogProps> = ({
  open,
  onClose,
  onSave,
  regulation,
}) => {
  const defaultValues = useMemo(
    () => ({
      code: '',
      group: {id: 0},
      emitedBy: {id: 0},
      type: {id: 0},
      scope: {
        id: 0
      },
      category: {id: 0},
      number: '',
      schedule: {
        id: undefined,
        deadline: '',
        recurrenceDays: '',
        periodicity: 0,
        daysNotice: 0,
        startDate: '',
        complianceDate: '',
      },
      document: {
        id: undefined,
        title: '',
        content: '',
        pdfUrl: '',
        referenceTitle: '',
        consolidatedText: '',
        description: '',
      },
      regulationUserResponsibilities:
        regulation?.regulationUserResponsibilities || [{id: 0}],
    }),
    [regulation]
  );

  const { control, handleSubmit, reset } = useForm<RegulationFormValues>({
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'regulationUserResponsibilities',
  });

  const addUserResponsibility = () => {
    append({
      user: {
        id: 0,
        email: '',
        azureId: '',
        username: '',
      },
      responsibility: {
        id: 0,
        type: '',
        description: '',
      },
    });
  };

  const [users, setUsers] = useState<Array<{ id: number; username: string }>>(
    []
  );

  const [responsibilities, setResponsibilities] = useState<
    Array<{ id: number; type: string; description: string }>
  >([]);

  useEffect(() => {
    const getUsernames = async () => {
      try {
        const usernames = await fetchUsernames();
        setUsers(usernames);
      } catch (error) {
        console.error('Error fetching usernames:', error);
      }
    };
    const getResponsibilities = async () => {
      try {
        const responsibilities = await fetchResponsibilities();
        setResponsibilities(responsibilities);
      } catch (error) {
        console.error('Error fetching responsibilities:', error);
      }
    };

    getResponsibilities();

    getUsernames();
  }, []);

  useEffect(() => {
    if (regulation) {
      const regulationForReset = {
        ...regulation,
        clientId: regulation.client?.id,
        groupId: regulation.group?.id,
        emittedById: regulation.emittedBy?.id,
        scopeId: regulation.scope?.id,
        typeId: regulation.type?.id,
        categoryId: regulation.category?.id,
      };
      reset(regulationForReset);
    } else {
      reset(defaultValues);
    }
  }, [regulation, reset, defaultValues]);

  const onSubmit: SubmitHandler<RegulationFormValues> = (formData) => {
    if (regulation?.id) {
      const updatedRegulation: Regulation = {
        ...formData,
        id: regulation.id,
      };
      onSave(updatedRegulation);
    } else {
      onSave({
        ...formData,
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        component : 'form',
        onSubmit : handleSubmit(onSubmit)
      }}>
      <DialogTitle>
        {regulation ? 'Editar Regulación' : 'Crear Regulación'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
          <RegulationFields control={control} />
          <ScheduleFields control={control} />
          <DocumentFields control={control} />
          <h3>Responsabilidades de Usuario</h3>
          {fields.map((field, index) => (
            <div key={field.id} style={{ position: 'relative' }}>
              <div style={{ paddingBottom: '5px' }}>
                Responsabilidad {index + 1}{' '}
              </div>
              <UserResponsibilityFields
                key={field.id}
                control={control}
                users={users}
                types={responsibilities}
                index={index}
              />
              <div
                onClick={() => remove(index)}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 0,
                  top: '-5px',
                  color: 'red',
                  fontSize: '0.9rem',
                }}
              >
                <DeleteIcon color="error" />
              </div>
            </div>
          ))}
          <Button onClick={addUserResponsibility} color="secondary">
            + Añadir Responsabilidad
          </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button type="submit" color="secondary">
          {regulation ? 'Guardar Cambios' : 'Crear'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegulationFormDialog;
