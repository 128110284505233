import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextField, MenuItem, CircularProgress, Box, Typography } from '@mui/material';
import {
  fetchClients,
  fetchGroups,
  fetchEmitters,
  fetchTypes,
  fetchCategories,
  fetchScopes,
} from '../../api/api';
import { RegulationFormValues } from '../DataTable/dataTypes';

interface RegulationFieldsProps {
  control: Control<RegulationFormValues>;
}

const RegulationFields: React.FC<RegulationFieldsProps> = ({ control }) => {
  const [clients, setClients] = useState([]);
  const [groups, setGroups] = useState([]);
  const [emitters, setEmitters] = useState([]);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const clientsData = await fetchClients();
        const groupsData = await fetchGroups();
        const emittersData = await fetchEmitters();
        const typesData = await fetchTypes();
        const categoriesData = await fetchCategories();
        const scopesData = await fetchScopes();
        setClients(clientsData);
        setGroups(groupsData);
        setEmitters(emittersData);
        setTypes(typesData);
        setCategories(categoriesData);
        setScopes(scopesData);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <h3>Regulación</h3>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Controller
          name="code"
          control={control}
          rules={{ required: 'El código de la regulación es requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              label="Código interno *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Ingrese'
            />
          )}
        />
        <Controller
          name="client.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'El cliente es requerido',
          validate: (value) => value !== 0 || 'Seleccione cliente', }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Cliente *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione:</em>
              </MenuItem>
              {clients.map((client: any) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="group.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido',
          validate: (value) => value !== 0 || 'Seleccione grupo', }}
          render={({ field, fieldState: { error } }) => (
            <TextField {...field}
              select
              label="Grupo"
              fullWidth margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}

            >
              <MenuItem disabled value={0}>
                <em>Seleccione:</em>
              </MenuItem>
              {groups.map((group: any) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.group}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="emittedBy.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido',
          validate: (value) => value !== 0 || 'Seleccione una opción', }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Emitido Por *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {emitters.map((emitter: any) => (
                <MenuItem key={emitter.id} value={emitter.id}>
                  {emitter.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="type.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido',
          validate: (value) => value !== 0 || 'Seleccione tipo', }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Tipo *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {types.map((type: any) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="category.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido',
          validate: (value) => value !== 0 || 'Seleccione una categoría', }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Categoría *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {categories.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.category}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="scope.id"
          control={control}
          defaultValue={0}
          rules={{
            required: 'Campo requerido',
            validate: (value) => value !== 0 || 'Seleccione un alcance',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              label="Alcance *"
              fullWidth
              margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                placeholder: 'Seleccione',
              }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {scopes.map((scope: any) => (
                <MenuItem key={scope.id} value={scope.id}>
                  {scope.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="number"
          control={control}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Número de registro *"
              fullWidth margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Ingrese'
            />
          )}
        />
      </Box>
    </>
  );
};

export default RegulationFields;
