import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';


type DetailItemProps = {
  title: string;
  content: string | null | number | undefined;
};

const DetailItem: React.FC<DetailItemProps> = ({ title, content }) => (
  <Box sx={{ marginBottom: 1 }}>
    <Typography variant="subtitle2" color={'secondary'}>
    {title}
    </Typography>
    <Divider/>
    <Typography variant="body2">{content}</Typography>
    <br/>
    
  </Box>
);

export default DetailItem;