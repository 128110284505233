import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNotification } from './useNotification';
import theme from '../../theme/theme';

function NotificationsMenu() {
  const notifications = useSelector((state: any) => state.notifications.notifications);
  const { markNotificationAsRead } = useNotification();

  // Verificar si hay notificaciones y extraer los mensajes
  const unreadNotifications = notifications ? notifications.filter((notification: any) => !notification.isRead) : [];

  // Estado de hover para controlar cuál ícono se muestra
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <MenuItem>
      <div>
        {unreadNotifications.length > 0 ? (
          <List>
            {/* Mapear los mensajes y mostrarlos en una lista */}
            {unreadNotifications.map((notification: any, index: number) => (
              <ListItem
                key={index}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
                onClick={() => markNotificationAsRead(notification.id)}
                sx={{
                  borderRadius: '5px',
                  ':hover': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                  },
                }}
              >
                <ListItemText primary={notification.message} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="marcar como leída"
                    disabled={notification.isRead}
                    
                  >
                    {hoverIndex === index ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" align="center">
            No existen nuevas notificaciones
          </Typography>
        )}
      </div>
    </MenuItem>
  );
}

export default NotificationsMenu;