import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const MainListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate('/')}>
        <ListItemIcon>
          <DashboardIcon sx={{ color: 'whitesmoke' }} />
        </ListItemIcon>
        <ListItemText primary="Regulaciones" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate('/schedule')}>
        <ListItemIcon>
          <CalendarMonthIcon sx={{ color: 'whitesmoke' }} />
        </ListItemIcon>
        <ListItemText primary="Calendario" />
      </ListItemButton>
      {/* <ListItemButton>
        <ListItemIcon>
          <PeopleIcon sx={{ color: 'whitesmoke' }} />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItemButton> */}
    </React.Fragment>
  );
};

export const SecondaryListItems = () => (
  <React.Fragment>
    {/* <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon sx={{ color: 'whitesmoke' }} />
      </ListItemIcon>
      <ListItemText primary="Reportes" />
    </ListItemButton> */}
    {/* Aquí irían otros botones secundarios */}
  </React.Fragment>
);
