import React, { useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import {
  Alert,
  Box,
  Button,
  IconButton,
  TableContainer,
  Tooltip,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRegulationAsync,
  deleteRegulationAsync,
  updateRegulationAsync,
} from '../../store/slices/regulationSlice';
import { AppDispatch, RootState } from '../../store/store';
import { Regulation, RegulationFormValues } from './dataTypes';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RegulationFormDialog from '../RegulationFormDialog/RegulationFormDialog';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { RegulationDetailCard } from '../RegulationDetailCard/RegulationDetailCard';
import formatDate from '../../helpers/formatDate';
import Spinner from '../Spinner/Spinner';
import DetailItem from '../DetailItem/DetailItem';
import { useForm } from 'react-hook-form';
import DialogPdf from '../DialogPdf/DialogPdf';
const DataTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const columns = useMemo<MRT_ColumnDef<Regulation>[]>(
    //column definitions...
    () => [
      {
        accessorKey: 'document.title',
        header: 'Título',
      },
      //la siguiente columna serviría en un SaaS
      // {
      //   accessorKey: 'client',
      //   header: 'Cliente',
      //   size: 1,
      // },
      //Agregar header para reporte normativo "Reporte Normativo"
      //Agregar header para "Reporte Interno"
      {
        accessorKey: 'code',
        header: 'Código',
        size: 1,
      },
      //Forma parte de los datos, pero el filtro va por otro lado, no en la tabla.
      //TENER EN CUENTA QUE PUEDE SER MÁS DE UNA
      // {
      //   accessorKey: 'group',
      //   header: 'Grupo',
      //   size: 1,
      // },
      {
        accessorKey: 'emittedBy.name',
        header: 'Emitido Por',
        size: 1,
      },
      {
        accessorKey: 'type.type',
        header: 'Tipo',
        size: 1,
      },
      {
        accessorKey: 'scope.name',
        header: 'Alcance',
        size: 1,
      },
      {
        accessorKey: 'category.category',
        header: 'Categoría',
        size: 1,
      },
      {
        accessorKey: 'document.pdfUrl',
        header: 'PDF',
        size: 1,
        Cell: ({ cell }) => {
          const url = cell.getValue() as string;
          const isPdf = url.toLowerCase().endsWith('.pdf');

          const [open, setOpen] = React.useState(false);

          const handleClick = () => {
            if (isPdf) {
              window.open(url, '_blank');
            } else {
              setOpen(true);
            }
          };

          const handleClose = () => {
            setOpen(false);
          };

          return (
            <div>
              <IconButton onClick={handleClick}>
                <OpenInNewIcon color={'primary'} />
              </IconButton>
              <DialogPdf open={open} onClose={handleClose} setOpen={setOpen} />
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: '',
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', ml: -1 }}>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleOpenEditDialog(row.original)}>
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton
                onClick={() => handleOpenDeleteDialog(row.original.id)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
    //end
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRegulation, setCurrentRegulation] = useState<Regulation | null>(
    null
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [regulationToDelete, setRegulationToDelete] = useState<number | null>(
    null
  );

  const handleOpenDeleteDialog = (regulationId: number | undefined) => {
    if (!regulationId) return;
    setRegulationToDelete(regulationId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (regulationToDelete) {
      dispatch(deleteRegulationAsync(regulationToDelete)).then(() => {
        handleCloseDeleteDialog();
      });
    }
  };

  const handleOpenCreateDialog = () => {
    setCurrentRegulation(null);
    setIsDialogOpen(true);
  };

  const handleOpenEditDialog = (regulation: Regulation) => {
    setCurrentRegulation(regulation);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveRegulation = (data: Regulation) => {
    if (data.id) {
      dispatch(updateRegulationAsync(data));
    } else {
      dispatch(addRegulationAsync(data));
    }

    setIsDialogOpen(false);
  };

  const regulations = useSelector(
    (state: RootState) => state.regulation.regulations as Regulation[]
  );
  const loading = useSelector((state: RootState) => state.regulation.loading);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={regulations}
        localization={MRT_Localization_ES}
        enableColumnFilters={false}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={false}
        initialState={{ showGlobalFilter: true }}
        createDisplayMode="modal"
        editDisplayMode="modal"
        renderTopToolbarCustomActions={() => (
          <Button onClick={handleOpenCreateDialog} color="secondary">
            Crear
          </Button>
        )}
        muiExpandButtonProps={({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
          sx: {
            transform: row.getIsExpanded()
              ? 'rotate(180deg)'
              : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) => (
          <>
            {row.original.document && (
              <RegulationDetailCard title="Documento">
                <DetailItem
                  title="Título de Referencia"
                  content={row.original.document.referenceTitle}
                />
                <DetailItem
                  title="Descripción"
                  content={row.original.document.description}
                />
                <DetailItem
                  title="Contenido"
                  content={row.original.document.content}
                />
                <DetailItem
                  title="Texto Consolidado"
                  content={row.original.document.consolidatedText}
                />
              </RegulationDetailCard>
            )}
            {row.original.schedule && (
              <RegulationDetailCard title="Calendario">
                <DetailItem
                  title="Fecha Límite:"
                  content={formatDate(row.original.schedule.deadline)}
                />
                <DetailItem
                  title="Dias de Anticipo:"
                  content={row.original.schedule.daysNotice}
                />
                <DetailItem
                  title="Periodicidad:"
                  content={row.original.schedule.periodicity}
                />
              </RegulationDetailCard>
            )}
            {row.original.regulationUserResponsibilities && (
              <RegulationDetailCard title="Responsables">
                <TableContainer sx={{ maxWidth: 650 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2" color={'secondary'}>
                            Descripción
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" color={'secondary'}>
                            Usuario
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" color={'secondary'}>
                            Email
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.original.regulationUserResponsibilities.map(
                        (regulationUserResponsibility) => (
                          <TableRow
                            key={regulationUserResponsibility.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell>
                              {
                                regulationUserResponsibility.responsibility
                                  ?.description
                              }
                            </TableCell>
                            <TableCell>
                              {regulationUserResponsibility.user.username}
                            </TableCell>
                            <TableCell>
                              {regulationUserResponsibility.user.email}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </RegulationDetailCard>
            )}
          </>
        )}
      />
      {isDialogOpen ? (
        <RegulationFormDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleSaveRegulation}
          regulation={currentRegulation}
        />
      ) : null}

      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title="Confirmar eliminación"
        message="¿Estás seguro de que deseas eliminar esta regulación?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteDialog}
      />
    </>
  );
};

export default DataTable;
