import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Control } from 'react-hook-form';
import { RegulationFormValues } from '../DataTable/dataTypes';

interface DocumentFieldsProps {
  control: Control<RegulationFormValues>;
}

const DocumentFields: React.FC<DocumentFieldsProps> = ({ control }) => {
  return (
    <>
      <h3>Documento</h3>
      <Controller
        name="document.title"
        control={control}
        rules={{ required: 'El título del documento es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Título del Documento *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese'
          />
        )}
      />
      <Controller
        name="document.content"
        control={control}
        rules={{ required: 'El contenido del documento es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Contenido del Documento *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese'
          />
        )}
      />
      <Controller
        name="document.pdfUrl"
        control={control}
        rules={{ required: 'La URL del pdf es un campo obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="URL del PDF *"
            fullWidth margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese' />
        )}
      />
      <Controller
        name="document.referenceTitle"
        control={control}
        rules={{ required: 'El título de referencia es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Título de Referencia *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese'
          />
        )}
      />
      <Controller
        name="document.consolidatedText"
        control={control}
        rules={{ required: 'El texto consolidado es un campo obligatorio' }}
        render={({ field, fieldState: { error }}) => (
          <TextField
            {...field}
            label="Texto Consolidado *"
            fullWidth
            margin="dense"
            multiline
            error={!!error}
            helperText={error ? error.message : null}
            rows={3}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese'
          />
        )}
      />
      <Controller
        name="document.description"
        control={control}
        rules={{ required: 'La descripción del texto es un campo obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Descripción *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder='Ingrese'
          />
        )}
      />
    </>
  );
};

export default DocumentFields;
