import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../store/store';
import { verifyToken } from '../api/api';
import { setCredentials } from '../store/slices/userSlice';
import Spinner from '../components/Spinner/Spinner';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      verifyToken(token).then((isValid) => {
        if (isValid) {
          dispatch(setCredentials({ isLoggedIn: true }));
        } else {
          sessionStorage.removeItem('access_token'); // limpiar el token si no es válido
          dispatch(setCredentials({ isLoggedIn: false }));
        }
        setIsLoading(false); // Finalizar la carga después de manejar el token
      });
    } else {
      setIsLoading(false); // Finalizar la carga si no hay token
    }
  }, [dispatch]);

  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);

  // TODO: componente de carga
  if (isLoading) {
    return <Spinner/>;
  }

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
