import React from 'react';
import { Controller } from 'react-hook-form';
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Control } from 'react-hook-form';
import { RegulationFormValues } from '../DataTable/dataTypes';

interface ScheduleFieldsProps {
  control: Control<RegulationFormValues>;
}

const ScheduleFields: React.FC<ScheduleFieldsProps> = ({ control }) => {
  return (
    <>
      <h3>Calendario</h3>
      <Box display={'flex'} gap={'10px'}>
        <Controller
          name="schedule.daysNotice"
          control={control}
          rules={{ required: 'Obligatorios',
          validate: (value) => value >= 0 || 'Seleccione un numero mayor o igual a 0',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type="number"
              label="Días de Anticipo"
              error={!!error}
              helperText={error ? error.message : null}
              sx={{ width: '150px' }}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder='Ingrese'
            />
          )}
        />
        <Controller
          name="schedule.deadline"
          control={control}
          rules={{ required: 'Obligatoria' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
              }}
            >
              <DatePicker
                label="Fecha Límite"
                format="DD/MM/YYYY"
                value={value ? dayjs(value) : null}
                sx={{
                  borderRadius: '5px',
                  ...(error && { border: '1px solid red !important' }),
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    ...(error && {
                      borderColor: 'red', // Color del borde en caso de error
                    }),
                    '&.Mui-focused fieldset': {
                      borderColor: error ? 'red' : 'primary.main', // Cambia el borde cuando está enfocado
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: error ? 'red' : 'inherit', // Cambia el color de la etiqueta
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'red', // Asegura que el texto de ayuda es rojo en caso de error
                  },
                }}
                onChange={(newValue) => onChange(newValue ? newValue : null)}
              />
              {error && (
                <span
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '3px',
                    marginLeft: '10px',
                  }}
                >
                  {error.message}
                </span>
              )}
            </div>
          )}
        />

        <Controller
          name="schedule.periodicity"
          control={control}
          defaultValue={0}
          rules={{ required: 'Obligatoria',
          validate: (value) => value !== 0 || 'Seleccione periodicidad', }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              defaultValue={0}
              label="Periodicidad *"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'Seleccione',
              }}
              sx={{ width: '250px' }}
            >
              <MenuItem disabled value={0}>
                <em>Seleccione:</em>
                </MenuItem>
              <MenuItem value="monthly">Mensual</MenuItem>
              <MenuItem value="quarterly">Trimestral</MenuItem>
              <MenuItem value="semiannually">Semestral</MenuItem>
              <MenuItem value="annually">Anual</MenuItem>

            </TextField>

          )}
        />

        {/* <Controller
          name="schedule.startDate"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DatePicker
              label="Fecha de Inicio"
              format="DD/MM/YYYY"
              value={value ? dayjs(value) : null}
              onChange={(newValue) => onChange(newValue ? newValue : null)}
            />
          )}
        />
        <Controller
          name="schedule.complianceDate"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <DatePicker
              label="Fecha de Cumplimiento"
              format="DD/MM/YYYY"
              value={value ? dayjs(value) : null}
              onChange={(newValue) => onChange(newValue ? newValue : null)}
            />
          )}
        /> */}
      </Box>
    </>
  );
};

export default ScheduleFields;
