import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { MenuItem, Select, InputLabel, FormControl, FormHelperText, TextField } from '@mui/material';
import { ResponsibilityType, User } from '../DataTable/dataTypes';

interface UserResponsibilityFieldProps {
  control: Control<any>;
  users: User[];
  types: ResponsibilityType[];
  index: number;
}

export const UserResponsibilityFields: React.FC<
  UserResponsibilityFieldProps
> = ({ control, users, types, index }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginBottom: '20px',
      }}
    >
      <FormControl variant="outlined" fullWidth>
        <Controller
          name={`regulationUserResponsibilities[${index}].user`}
          control={control}
          defaultValue={0}
          rules={{
            required: 'El usuario es requerido',
            validate: (value) => value !== 0 || 'El usuario es requerido',
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              <TextField
                {...field}
                error={!!error}
                label="Usuario"
                helperText={error ? error.message : null}
                select
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem disabled key={0} value={0}>
                  Seleccione Usuario
                </MenuItem>
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <Controller
          name={`regulationUserResponsibilities[${index}].responsibility`}
          control={control}
          defaultValue={0}
          rules={{
            required: 'El tipo de responsabilidad es requerido',
            validate: (value) => value !== 0 || 'El tipo de responsabilidad es requerido',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Tipo de Responsabilidad"
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem disabled key={0} value={0}>
                Seleccione Responsabilidad
              </MenuItem>
              {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    </div>
  );
};
