import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/routes';
import { Provider } from 'react-redux';
import store from './store/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/msal';
import { ThemeProvider } from '@emotion/react';
import theme from './theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const msalInstance = new PublicClientApplication(msalConfig);

dayjs.locale('es');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Santiago');

// Establece la zona horaria por defecto si es necesario

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
