import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCurrentUser, fetchUserNotifications } from '../../api/api';

const initialState = {
  user: {

  },
  notifications: [],
  loggedIn: false,
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const user = await fetchCurrentUser();
      return user;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  'user/fetchNotifications',
  async (userId: number, { rejectWithValue }) => {
    try {
      const notifications = await fetchUserNotifications(userId);
      return notifications;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error fetching notifications');
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      state.loggedIn = true;
    },
    logout: (state) => {
      state.user = {};
      state.loggedIn = false;
      sessionStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = {
          ...action.payload,
          id: action.payload.sub,
        };
        state.loggedIn = true;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user = {};
        state.loggedIn = false;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications = [];
      });

  },
});

export const { setCredentials, logout } = userSlice.actions;

export default userSlice.reducer;
