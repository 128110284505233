import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createRegulation, deleteRegulation, fetchRegulations, updateRegulation as updatedRegulationApi } from '../../api/api';
import { Regulation } from '../../components/DataTable/dataTypes';

interface RegulationState {
  regulations: Regulation[];
  loading: boolean;
}

const initialState: RegulationState = {
  regulations: [],
  loading: false
};

export const getRegulations = createAsyncThunk(
  'regulation/getRegulations',
  async (_, { getState, dispatch }) => {
    try {
      const regulations = await fetchRegulations();
      return regulations;
    } catch (error) {
      console.error("Error fetching regulations:", error);
      throw error;
    }
  }
);

export const addRegulationAsync = createAsyncThunk(
  'regulation/addRegulation',
  async (regulation: Regulation) => {
    const newRegulation = await createRegulation(regulation);
    return newRegulation;
  }
);


export const updateRegulationAsync = createAsyncThunk(
  'regulation/updateRegulation',
  async (regulation: Regulation) => {
    const updatedRegulation = await updatedRegulationApi(regulation);
    return updatedRegulation;
  }
);

export const deleteRegulationAsync = createAsyncThunk(
  'regulation/deleteRegulation',
  async (regulationId: number, { rejectWithValue }) => {
    try {
      await deleteRegulation(regulationId);
      return regulationId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);



const regulationSlice = createSlice({
  name: 'regulation',
  initialState,
  reducers: {
    addRegulation(state, action: PayloadAction<Regulation>) {
      state.regulations.push(action.payload);
    },
    updateRegulation(state, action: PayloadAction<Regulation>) {
      const index = state.regulations.findIndex(r => r.id === action.payload.id);
      if (index !== -1) {
        state.regulations[index] = action.payload;
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegulations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRegulations.fulfilled, (state, action) => {
        state.regulations = action.payload;
        state.loading = false;
      })
      .addCase(getRegulations.rejected, (state) => {
        state.loading = false;
        // manejar un estado de error
      })
      .addCase(addRegulationAsync.fulfilled, (state, action) => {
        state.regulations.push(action.payload);
      })
      .addCase(updateRegulationAsync.fulfilled, (state, action) => {
        const index = state.regulations.findIndex(r => r.id === action.payload.id);
        if (index !== -1) {
          state.regulations[index] = action.payload;
        }
      })
      .addCase(deleteRegulationAsync.fulfilled, (state, action) => {
        state.regulations = state.regulations.filter(regulation => regulation.id !== action.payload);
      })
  }
});

export const { addRegulation, updateRegulation, setLoading } = regulationSlice.actions;

export default regulationSlice.reducer;
