import { format } from 'date-fns';
import { es } from 'date-fns/locale';

// Función auxiliar para formatear las fechas
const formatDate = (dateString: string | null): string => {
  if (dateString === null) return ''
  const date = new Date(dateString);
  return format(date, "EEEE d 'de' MMMM yyyy", { locale: es });
};

export default formatDate;