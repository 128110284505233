import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import CalendarComponent from '../CalendarComponent/CalendarComponent';
import TaskBar from '../TaskBar/TaskBar';
import { CalendarProvider } from './CalendarContext';

const ScheduleDashboard: React.FC = () => {
  return (
    <CalendarProvider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {/* 70% del espacio en pantallas medianas a grandes */}
          <Paper
            // elevation={3}
            sx={{ padding: 2 }}
          >
            <Typography variant="h6">Calendario</Typography>
            <CalendarComponent />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          {' '}
          {/* 30% del espacio en pantallas medianas a grandes */}
          <Paper
            // elevation={3}
            sx={{ padding: 2 }}
          >
            <br />
            <Typography variant="h5">Tarea Seleccionada</Typography>
            <br />
            <TaskBar />
          </Paper>
        </Grid>
      </Grid>
    </CalendarProvider>
  );
};

export default ScheduleDashboard;
