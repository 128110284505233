// TaskBar.tsx
import React, { useContext } from 'react';
import CalendarContext from '../ScheduleDashboard/CalendarContext';
import DetailItem from '../DetailItem/DetailItem';

const TaskBar: React.FC = () => {
  const { selectedEvent } = useContext(CalendarContext);

  if (!selectedEvent) {
    return <div>No hay evento seleccionado.</div>;
  }

  const { details } = selectedEvent;

  if (!details || !details.document) {
    return <div>No hay detalles para mostrar.</div>;
  }

  return (
    <div>
      <h3>
        {details.code} - {details.client.name}
      </h3>
      <DetailItem title="Emisor" content={details.emittedBy.name} />
      <DetailItem title="Grupo" content={details.group.group} />
      <DetailItem
        title="Categoría"
        content={`${details.category.category}, ${details.scope.name}`}
      />
      <DetailItem title="Tipo" content={details.type.type} />
      <DetailItem
        title="Descripción del documento"
        content={details.document.description}
      />
    </div>
  );
};

export default TaskBar;
