import axios from 'axios';
import * as msal from '@azure/msal-browser';
import { msalConfig } from '../auth/msal';
import { setCredentials } from '../store/slices/userSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { Regulation } from '../components/DataTable/dataTypes';

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

axios.defaults.baseURL = API_BASE_URL;

// Agregar interceptor de respuesta
axios.interceptors.response.use(
  (response) => {
    // Si la respuesta es exitosa, simplemente la devuelve sin modificar.
    return response;
  },
  (error) => {
    // Chequea si el error es un error 401 - no autorizado.
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const getAzureAccesToken = async () => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const account = msalInstance.getAllAccounts()[0];

  if (!account) {
    throw new Error('No hay cuenta activa en MSAL');
  }

  const silentRequest = {
    scopes: ['user.read'], // Ajusta según los scopes que necesitas.
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    console.error('Error al obtener el token de acceso con MSAL', error);
    throw error;
  }
};

interface LoginResponse {
  auth: boolean;
  user_data: UserData | null;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  roles: string[];
  azureId: string;
  sub: string;
}

//AUTH
export const login = async (dispatch: Dispatch): Promise<LoginResponse> => {
  const accessToken = await getAzureAccesToken();

  try {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error de autenticación');
    }

    const data = await response.json();

    if (data.access_token) {
      sessionStorage.setItem('access_token', data.access_token);
      dispatch(setCredentials({ user: data.user_data }));

      return { auth: true, user_data: data.user_data };
    } else {
      return { auth: false, user_data: null };
    }
  } catch (error) {
    console.error('Error:', error);
    return { auth: false, user_data: null };
  }
};

export const verifyToken = async (token: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/verifyToken`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.isValid;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};

export const fetchRegulations = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/regulations?relations=client,emittedBy,group,schedule,document,scope,category,type,notifications,regulationUserResponsibilities.user,regulationUserResponsibilities.responsibility`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching regulations:', error);
    throw error;
  }
};

//CRUD REGULATIONS
export const createRegulation = async (
  newRegulation: Regulation
): Promise<Regulation> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/regulations`,
      newRegulation,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating regulation:', error);
    throw error;
  }
};
export const updateRegulation = async (
  regulation: Regulation
): Promise<Regulation> => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/regulations/${regulation.id}`,
      regulation,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating regulation:', error);
    throw error;
  }
};
export const deleteRegulation = async (regulationId: number): Promise<void> => {
  try {
    await axios.delete(`${API_BASE_URL}/regulations/${regulationId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.access_token}`,
      },
    });
  } catch (error) {
    console.error('Error deleting regulation:', error);
    throw error;
  }
};

//REGULATION SATELLITES
export const fetchClients = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/clients`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};
export const fetchGroups = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/groups`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw error;
  }
};
export const fetchEmitters = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/emitters`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching emitters:', error);
    throw error;
  }
};
export const fetchTypes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/types`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching types:', error);
    throw error;
  }
};
export const fetchCategories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/categories`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};
export const fetchScopes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/scopes`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

//USER
export const fetchUser = async (userId: number): Promise<UserData> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching user data');
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};
export const fetchCurrentUser = async (): Promise<UserData> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching current user data');
    }
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};
export const fetchUsernames = async (): Promise<
  Array<{ id: number; username: string }>
> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/usernames`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching usernames');
    }
  } catch (error) {
    console.error('Error fetching usernames:', error);
    throw error;
  }
};

//NOTIFICATIONS
export const fetchUserNotifications = async (userId: number) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/users/${userId}/notifications`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`, // Asume que el token de acceso ya está almacenado en sessionStorage
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching user notifications');
    }
  } catch (error) {
    console.error('Error fetching user notifications:', error);
    throw error;
  }
};
export const markNotificationAsRead = async (
  notificationId: number
): Promise<any> => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/notifications/${notificationId}`,
      { isRead: true },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error('Error marking notification as read');
    }
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

//RESPONSIBILITIES
export const fetchResponsibilities = async (): Promise<
  Array<{ id: number; type: string; description: string }>
> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/responsibilities`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching responsibilities');
    }
  } catch (error) {
    console.error('Error fetching responsibilities:', error);
    throw error;
  }
};
