// notificationsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserNotifications, markNotificationAsRead } from '../../api/api';
import { Notification } from '../../components/DataTable/dataTypes';

// Async Thunks

export const markNotificationAsReadThunk = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (notificationId: number) => {
    const markAsRead = await markNotificationAsRead(notificationId);
    return markAsRead;
  }
);

export const fetchNotifications = createAsyncThunk(
  'user/fetchNotifications',
  async (userId: number, { rejectWithValue }) => {
    try {
      const notifications = await fetchUserNotifications(userId);
      return notifications;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error fetching notifications');
    }
  }
);



const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [] as Notification[],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(markNotificationAsReadThunk.fulfilled, (state, action) => {
        const newNotifications = state.notifications = state.notifications.map((notification) => {
          if (notification.id === action.payload.id) {
            return {
              ...notification,
              isRead: true
            }
          }
          return notification;
        })
        state.notifications = newNotifications

      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications = [];
      });
  },
});

export default notificationsSlice.reducer;