import { useNavigate } from 'react-router-dom';
import { login } from '../../api/api';
import { loginRequest } from '../../auth/msal';
import { useMsal } from '@azure/msal-react';
import { Container, Button, Typography, Box, CssBaseline } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import { useDispatch } from 'react-redux';

function Login() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const popup = await instance.loginPopup(loginRequest);
      if (!popup) return;

      const result = await login(dispatch);
      if (result?.auth && result.user_data?.id) {
        navigate('/');
      } else {
        // Handle error: show user-friendly error message or log
      }
    } catch (error) {
      console.error('Login error:', error);
      // Handle error: show user-friendly error message or log
    }
  };

  return (
    <Container
      sx={{
        height: '100vh',
        minWidth: '100vw',
        backgroundImage: "url('/bg-login.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CssBaseline />
      <Box
        component="main"
        p={10}
        sx={{
          border: '1px solid whitesmoke',
          backgroundColor: '#22292E',
          color: 'whitesmoke',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h3" mb={3}>
          Compliance App
        </Typography>
        <GavelIcon sx={{ fontSize: '50px' }} />
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogin}
          sx={{
            mt: 3,
            mb: 2,
            px: 10,
            textTransform: 'none',
            borderRadius: 50,
            fontWeight: 'bold',
          }}
        >
          Ingresar
        </Button>
      </Box>
    </Container>
  );
}

export default Login;
