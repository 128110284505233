import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#19857b', //Verde seleccionado,  Azul claro #7abcd0 2da opc
    },
    secondary: {
      main: '#19857b', // Verde vibrante
    },
    info: {
      main: '#2a9df4', // Azul claro
    },
    success: {
      main: '#81c784', // Verde claro
    },
    warning: {
      main: '#ffb300', // Amarillo anaranjado
    },

    error: {
      main: '#ff4444', // Rojo intenso
    },
    background: {
      default: '#fff', // Blanco, Beige claro segunda opc #f5f5f5
      paper: '#fff', // Blanco
    },
    text: {
      primary: '#333', // Gris oscuro
      secondary: '#666', // Gris medio
    },
  },
  typography: {
    fontFamily: [
      '"Raleway"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: '2.2rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none', // Para evitar mayúsculas automáticas en los botones
    },
  },
});

export default theme;